<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">EDIT SCHEDULE</p>
    </div>
    <div class="dialog-content">
      <b-container fluid>
        <b-form @submit="onSubmit">
          <b-form-group label="Educator"
                        :label-cols="2"
                        label-for="educator"
                        horizontal>
            <multiselect class="educator-select"
                         :custom-label="educatorName"
                         :max-height="150"
                         :multiple="false"
                         :options="educators"
                         placeholder="Select an Educator"
                         :searchable="true"
                         track-by="user_id"
                         v-model="form.educator"
                         :disabled="disableEducator"
            />
          </b-form-group>

          <b-form-group label="Date"
                        :label-cols="2"
                        label-for="date"
                        horizontal>
            <b-form-input id="date"
                          type="date"
                          aria-describedby="date-input"
                          @input="$v.form.date.$touch()"
                          v-model.trim="$v.form.date.$model"
                          :state="!$v.form.date.$error">
            </b-form-input>
            <b-form-invalid-feedback id="date-input"
                                     v-if="$v.form.date.$error">
              <span v-if="!$v.form.date.required">
                Date is required.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Start Time"
                        :label-cols="2"
                        label-for="startTime"
                        horizontal>
            <b-input-group class="mb-0">
              <b-form-input id="startTime"
                            type="time"
                            aria-describedby="startTime-input"
                            @input="$v.form.startTime.$touch()"
                            v-model.trim="$v.form.startTime.$model"
                            :state="!$v.form.startTime.$error"
                            :value="form.startTime">
              </b-form-input>
              <template v-slot:append>
                <b-form-select v-model="selectedTZ"
                               :options="timezones"
                               @input="$v.selectedTZ.$touch()"
                               :state="!$v.selectedTZ.$error">
                </b-form-select>
              </template>
              <b-form-invalid-feedback id="startTime-input"
                                      v-if="$v.form.startTime.$error">
                <span v-if="!$v.form.startTime.required">
                  Start Time is required.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
            <small id="start-time" class="form-text text-muted">
              Your Timezone:
              <b>{{ userTz | timezoneAbbreviation }}</b>  |
              Influencer's Timezone:
              <b v-if="$v.selectedTZ.validTz">
                {{ data.infTimezone | timezoneAbbreviation }}
              </b>
              <b v-else>
                Not Available
              </b>
            </small>
          </b-form-group>

          <b-form-group label="Duration"
                        :label-cols="2"
                        label-for="duration"
                        horizontal>
            <b-form-select v-model="$v.form.duration.$model"
                           :options="durations"
                           size="sm"
                           aria-describedby="duration-select"
                           @input="$v.form.duration.$touch()"
                           :state="!$v.form.duration.$error">
            </b-form-select>
            <b-form-invalid-feedback id="duration-select"
                                     v-if="$v.form.duration.$error">
              <span v-if="!$v.form.duration.required">
                Duration is required.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Notes"
                        :label-cols="2"
                        label-for="notes"
                        horizontal>
            <b-form-textarea v-model="form.notes"
                             rows="3"
                             max-rows="6"
                             size="sm"
                             placeholder="Enter Notes">
            </b-form-textarea>
          </b-form-group>

          <div class="d-flex bd-highlight mb-3">
            <div class="mr-auto p-2 bd-highlight">
              <b-button class="text-center"
                        variant="danger"
                        type="button"
                        size="sm"
                        @click="cancelSchedule(data)"
                        v-if="data.status === 'reserved'">
                Cancel Schedule
              </b-button>
            </div>
            <div class="p-2 bd-highlight">
              <b-button class="text-center"
                        type="submit"
                        variant="outline-primary"
                        size="sm"
                        :disabled="$v.$invalid || action == null">
                Submit
              </b-button>
              <b-button class="text-center ml-1"
                        type="reset"
                        variant="outline-warning"
                        size="sm">
                Reset
              </b-button>
              <b-button class="text-center ml-1 "
                        variant="outline-danger"
                        @click="$emit('close')"
                        size="sm">
                Close
              </b-button>
            </div>
          </div>
        </b-form>
      </b-container>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
      </loading>
    </div>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const Multiselect = () => import('vue-multiselect');
    
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment-timezone';
  import FilterMixin from '@/mixins/FilterMixin';
  import { mapActions } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
 
  export default {
    name   : 'EditSchedule',
    mixins : [
      FilterMixin,
    ],
    props : {
      data : Object,
    },
    data () {
      return {
        isLoading  : false,
        userTz     : this.$store.getters['user/user'].timezone,
        selectedTZ : this.data.infTimezone,
        validTz    : true,
        timezones  : [ {
          value    : null,
          text     : 'Timezone',
          disabled : true,
        }, {
          value : 'America/New_York',
          text  : 'EST/EDT',
        }, {
          value : 'America/Chicago',
          text  : 'CST/CDT',
        }, {
          value : 'America/Denver',
          text  : 'MDT',
        }, {
          value : 'America/Phoenix',
          text  : 'MST No DST',
        }, {
          value : 'America/Los_Angeles',
          text  : 'PST/PDT',
        }, {
          value : 'America/Anchorage',
          text  : 'AKST/AKDT',
        }, {
          value : 'America/Adak',
          text  : 'HDT',
        }, {
          value : 'Pacific/Honolulu',
          text  : 'HST No DST',
        } ],
        schools   : [],
        educators : [],
        
        form : {
          educator  : null,
          date      : null, //this.data.dateDisp,
          startTime : null, //this.data.startDisp,
          duration  : null, //this.data.duration,
          notes     : null, //this.data.notes,
        },
        disableEducator : (this.data.status === 'reserved') ? true : false,
        action          : null,
      }
    },
    components : {
      Loading,
      Multiselect,
    },
    computed : {
      durations() {
        const durations = [{
          value : null,
          text  : 'Select duration',
        }];
        for (let i = 3; i <= 15; i++) {
          durations.push({
            value : i,
            text  : i + ' minutes',
          });
        }

        return durations;
      },
    },
    watch : {
      selectedTZ(value) {
        if (value) {
          this.validTz = true;
          if ([ 'America/New_York', 'America/Chicago', 'America/Denver',
                'America/Phoenix', 'America/Los_Angeles', 'America/Anchorage',
                'America/Adak', 'Pacific/Honolulu' ].indexOf(value) === -1)
            this.validTz = false;
          
          this.updateScheduleDisp(value);
        }
        this.formUpdate();
      },
    },
    methods : {
      ...mapActions({
        getSchedules : 'broadcast/getSchedules',
      }),

      /**
       * Update Schedule Display
       * @
       */
      updateScheduleDisp(timezone) {
        const start = moment(this.data.start);
        const end = moment(this.form.end);

        this.form.date = start.tz(timezone).format('YYYY-MM-DD');
        this.form.startTime = start.tz(timezone).format('HH:mm');
        this.form.endTime = end.tz(timezone).format('HH:mm');
      },

      /**
       * Custom Label for Educator Multiselect
       */
      educatorName({ firstname, lastname }) {
        return `${firstname} ${lastname}`;
      },

      /**
       * Get School Educators
       * @param schoolId
       */
      getEducators(schoolId = '') {
        this.educators = [];
        const parameter = (schoolId) ? { schoolId : parseInt(schoolId) } : {};

        this.$http.get('api/school/educators', {
          params : parameter,
        }).then(response => {
          this.educators = response.data;

          // get educator's index
          const ind = _.findIndex(
            response.data, _.matchesProperty('user_id', this.data.educatorId)
          );

          // set selected educator
          this.form.educator = this.educators[ind];
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Additional Form Validation
       */
      formUpdate() {
        this.action = null;

        if (this.form.date != this.data.dateDisp ||
          this.form.duration != this.data.duration ||
          this.form.startTime != this.data.startDisp ||
          this.form.notes != this.data.notes ||
          this.selectedTZ != this.data.infTimezone) {
          if (this.data.status === 'reserved')
            this.action = 'update';
          else
            this.action = 'reserve';
        } else {
          if (this.data.status === 'open' &&
            this.form.educator != undefined)
            this.action = 'reserve';
        }
      },

      /**
       * Edit Schedule
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;

        if ((this.form.date == this.data.dateDisp ||
          this.form.duration == this.data.duration ||
          this.form.startTime == this.data.startDisp ||
          this.form.notes == this.data.notes) &&
          this.data.status === 'open' &&
          this.form.educator != undefined) {
          // if educator field is updated
          // set slot as reserved
          this.reserveSchedule();
        } else {
          // set default time zone
          moment.tz.setDefault(this.selectedTZ);

          const startTime = this.form.date + ' ' + this.form.startTime + ':00';
          const endTime = moment(startTime).add(this.form.duration, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
          const fields = { start_time : startTime, end_time : endTime };
          let oldSchedule = {};

          if (this.form.notes)
            fields.notes = this.form.notes;

          if (this.form.startTime != this.data.startDisp ||
            this.form.duration != this.data.duration ||
            this.form.date != this.data.dateDisp) {
            oldSchedule = {
              startTime : this.data.start,
              endTime   : this.data.end,
            };
          }

          this.$http.put('api/broadcast/slot/update', {
            broadcastSlotId : this.data.id,
            timezone        : this.data.infTimezone,
            influencerId    : this.data.influencerId,
            status          : this.data.status,
            updateFields    : fields,
            oldSchedule     : oldSchedule,
          })
            .then(() => {
              if (this.data.status === 'open' &&
                this.form.educator != undefined) {
                // if educator field is also updated
                // reserve schedule
                this.reserveSchedule();
              } else {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Updated schedule successfully!',
                });
                this.isLoading = false;
                this.$emit('close');
              }
            }).catch(() => {
              this.$notify({
                group : 'notif',
                type  : 'error',
                title : 'Failed',
                text  : 'Oops! Something went wrong!',
              });
            });
        }
      },

      /**
       * Reserve Schedule
       */
      reserveSchedule() {
        this.$http.put('api/broadcast/slot/reserve', {
          status           : 'reserved',
          educatorId       : this.form.educator.user_id,
          broadcastSlotId  : this.data.id,
          educatorTimezone : this.form.educator.timezone,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Reserved schedule successfully!',
          });
          this.isLoading = false;
          this.$emit('close');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Cancel Reservation
       * @param data
       */
      cancelSchedule(data) {
        this.$emit('close');
        this.$modal.show('dialog', {
          title : 'Cancel Schedule',
          text  : 'Are you sure you want to cancel <b>' + data.influencer +
            '\'s</b> Live Streaming schedule for <b>' + data.educator + '</b>?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.put('api/broadcast/slot/cancel', {
                'broadcastSlotId' : data.id,
                'educatorId'      : data.educatorId,
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success',
                  text  : 'Cancelled ' + data.influencer +
                    '\'s Live Streaming schedule for ' + data.educator +
                    ' successfully!',
                });
                this.getSchedules(); // update schedules list
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },
    },
    created() {
      this.$watch('form', this.formUpdate, {
        deep : true,
      });
    },
    mounted() {
      this.getEducators();
      this.updateScheduleDisp(this.data.infTimezone);

      this.form.date = this.data.dateDisp;
      this.form.startTime = this.data.startDisp;
      this.form.duration = this.data.duration;
      this.form.notes = this.data.notes;
    },
    validations : {
      form : {
        educator  : {},
        date      : { required },
        startTime : { required },
        duration  : { required },
        notes     : {},
      },
      selectedTZ : {
        required,
        validTz() {
          return this.validTz;
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/edit-schedule";
</style>